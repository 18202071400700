class MessageParser {
    constructor(actionProvider, state) {
      this.actionProvider = actionProvider;
      this.state = state
    }

    
    parse = (message) => {
      const lowerCase = message.toLowerCase();
      if(lowerCase.includes("hi") || lowerCase.includes("hello") || lowerCase.includes("yo") || lowerCase.includes("hey") || lowerCase.includes("whats up") || lowerCase.includes("what's up") || lowerCase.includes("How are you?") || lowerCase.includes("What's new?")){
        return this.actionProvider.handleUserRegistration(message);
      }
      else if(lowerCase.includes("reset")){
        return this.actionProvider.handleDefault(message);

      }
      return this.actionProvider.handleDefault(message);
    };
  }
  
  export default MessageParser;