import axios from "axios";
import random from "random-string-generator";
// import apiURL from "https://uatv1.imonitorplus.com/sierservice/v2.0/socialChannel/whatsappForPhilippinesWeb"

const apiURL = "https://uatv1.imonitorplus.com/sierservice/v2.0/socialChannel/whatsappForPhilippinesWeb";
// const apiURL = "https://uatv1.imonitorplus.com/service_redis/v2.0/socialChannel/whatsappForPhilippinesWeb";
class ActionProvider {
  constructor(createChatBotMessage, setStateFunc, createClientMessage) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
    this.createClientMessage = createClientMessage
  }

    handleUserRegistration = (message) => {
    let userId;
    let requestPayload;
    let bodyMessage;
    if(message.toLowerCase() == "reset"){
      bodyMessage = "reset";
    }else{
      bodyMessage = message;
    }
    if(sessionStorage.getItem("userId")){
      userId = sessionStorage.getItem("userId");
      requestPayload = {contacts:[{"profile":{"name":""},"wa_id":userId}],"messages":[{"from":userId,"id":userId,"text":{"body":bodyMessage},"timestamp":"1689225224","type":"text"}]};
    }
    else{
      const uuId = random(12,'numeric');
      sessionStorage.setItem("userId", uuId);
      userId = sessionStorage.getItem("userId");
      requestPayload = {contacts:[{"profile":{"name":""},"wa_id":userId}],"messages":[{"from":userId,"id":userId,"text":{"body":bodyMessage},"timestamp":"1689225224","type":"text"}]};
    }
    axios.post(apiURL,requestPayload)
    .then((response) => 
    {
        let firstmessages;
        let headermessage;
        let list;
        let buttons;
        let temp;
        if(response.data.data.interactive.action.sections){
          headermessage = response.data.data.interactive.body.text;
          list = response.data.data.interactive? response.data.data.interactive.action.sections[0].rows : [];
          temp = {headermessage, list}
          firstmessages = this.createChatBotMessage(
            `${temp.headermessage}`,
            { widget: "interactivelist", withAvatar: true, payload:{res:temp} }
            );
            this.addMessageToBotState(firstmessages);        
          }
        else{
          headermessage = response.data.data.interactive.body.text;
          buttons = response.data.data.interactive? response.data.data.interactive.action.buttons : [];
          temp={headermessage, buttons}
          firstmessages = this.createChatBotMessage(
            `${temp.headermessage}`,
            { widget: "menu", withAvatar: true, payload:{res:temp} }
            );
            this.addMessageToBotState(firstmessages);
        }
      }
      ).catch(error => {
        console.log(error)
      })
    }

    handleMenuClick= (e, title, id, desc) => {
      e.preventDefault();
      const userId = sessionStorage.getItem("userId");
      let firstmessages;
      let requestPayload = {contacts:[{"profile":{"name":""},"wa_id":userId,}],messages:[{"context":{"from":userId,"id":userId,},"from":userId,"id":userId,"interactive":{"list_reply":{"id":id,"title":title},"type":"list_reply"},"timestamp":"1689225350","type":"interactive"}]}
      axios.post(apiURL,requestPayload)
      .then((response) => 
      {
        let headermessage;
        let list;
        let buttons;
        let temp;
        if(response.data.data.rasa){
          console.log("if>if",response.data.data.rasa)
          headermessage = response.data.data.rasa.text[0];
          buttons = response.data.data.rasa? response.data.data.rasa.rasaBotMenu : [];
          temp ={headermessage,buttons}
          firstmessages = this.createChatBotMessage(
            `${temp.headermessage}`,
            { widget: "rasamenulist", withAvatar: true , payload:{res : temp}}
            );
            this.addMessageToBotState(firstmessages);
        }
        else{
          console.log("if>else")
          headermessage = response.data.data.interactive.body.text;
          list = response.data.data.interactive? response.data.data.interactive.action.sections[0].rows : [];
          temp = {headermessage, list}
          firstmessages = this.createChatBotMessage(
            `${temp.headermessage}`,
            { widget: "interactivelist1", withAvatar: true, payload:{res: temp}}
            );
            this.addMessageToBotState(firstmessages);        
        }
      }
      ).catch(error => {
        console.log(error)
      })

      let clientmessages = this.createClientMessage(
        `${title}`,
      );

      this.addMessageToBotState(clientmessages);
    };

    handleMenuClick1= (e, title, id, desc) => {
      e.preventDefault();
      const userId = sessionStorage.getItem("userId");
      let firstmessages;
      let requestPayload = {contacts:[{"profile":{"name":""},"wa_id":userId,}],messages:[{"context":{"from":userId,"id":userId,},"from":userId,"id":userId,"interactive":{"list_reply":{"id":id,"title":title},"type":"list_reply"},"timestamp":"1689225350","type":"interactive"}]}
      axios.post(apiURL,requestPayload)
      .then((response) => 
      {
        let headermessage;
        let list;
        let buttons;
        let temp;
        if(response.data.data.rasa){
          headermessage = response.data.data.rasa.text[0];
          buttons = response.data.data.rasa? response.data.data.rasa.rasaBotMenu : [];
          temp ={headermessage, buttons}
          firstmessages = this.createChatBotMessage(
            `${headermessage}`,
            { widget: "rasamenulist", withAvatar: true , payload:{res : temp}}
            );
            this.addMessageToBotState(firstmessages);
        }
        else{
          headermessage = response.data.data.interactive.body.text;
          list = response.data.data.interactive? response.data.data.interactive.action.sections[0].rows : [];
          temp = {headermessage, list}
          firstmessages = this.createChatBotMessage(
            `${headermessage}`,
            { widget: "interactivelist1", withAvatar: true, payload:{res: temp}}
            );
            this.addMessageToBotState(firstmessages);        
        }
      }
      ).catch(error => {
        console.log(error)
      })
      if(desc){

        let clientmessages = this.createClientMessage(
          `${desc}`,
        );
  
        this.addMessageToBotState(clientmessages);
      }
      else{
        let clientmessages = this.createClientMessage(
          `${title}`,
        );
  
        this.addMessageToBotState(clientmessages);
      }
    };

    handleListClick = (title, id) => {
      const userId = sessionStorage.getItem("userId");
      let firstmessages;
      let requestPayload = {contacts:[{"profile":{"name":""},"wa_id":userId,}],messages:[{"context":{"from":userId,"id":userId,},"from":userId,"id":userId,"interactive":{"button_reply":{"id":id,"title":title},"type":"button_reply"},"timestamp":"1689225350","type":"interactive"}]}
      axios.post(apiURL,requestPayload)
      .then((response) => 
      {
        let headermessage = response.data.data.interactive.body.text;
        let list = response.data.data.interactive? response.data.data.interactive.action.sections[0].rows : [];
        let temp = {headermessage, list}
        return temp;
      }
      )
      .then((res)=>{
        firstmessages = this.createChatBotMessage(
          `${res.headermessage}`,
          { widget: "interactivelist", withAvatar: true, payload:{res:res} }
          );
          this.addMessageToBotState(firstmessages);

      }).catch(error => {
        console.log(error)
      })

      let clientmessages = this.createClientMessage(
        `${title}`,
      );

      this.addMessageToBotState(clientmessages);
    };
    
    handleRasaRequest = (message) => {
      const userId = sessionStorage.getItem("userId");
      let firstmessages;
      let requestPayload = {"contacts":[{"profile":{"name":""},"wa_id":userId}],"messages":[{"context":{"from":userId,"id":userId},"from":userId,"id":userId,"interactive":{"list_reply":{"description":message,"id":message,"title":" "},"type":"list_reply"},"timestamp":"1700139378","type":"interactive"}]};
      axios.post(apiURL,requestPayload)
      .then((response) => 
      {
        let headermessage;
        let buttons;
        let temp;
        if(response.data.data.rasa){
          headermessage = response.data.data.rasa.text[0];
          buttons = response.data.data.rasa? response.data.data.rasa.rasaBotMenu : [];
          temp ={headermessage,buttons}
          firstmessages = this.createChatBotMessage(
            `${temp.headermessage}`,
            { widget: "rasamenulist", withAvatar: true , payload:{res : temp}}
            );
            this.addMessageToBotState(firstmessages);
        }
        else{
          let headermessage = response.data.data.interactive.body.text;
          let list = response.data.data.interactive? response.data.data.interactive.action.sections[0].rows : [];
          temp = {headermessage, list}
          firstmessages = this.createChatBotMessage(
            `${temp.headermessage}`,
            { widget: "interactivelist1", withAvatar: true, payload:{res: temp}}
            );
            this.addMessageToBotState(firstmessages);       
        }
      }
      ).catch(error => {
        console.log(error)
      })

      let clientmessages = this.createClientMessage(
        `${message}`,
      );

      this.addMessageToBotState(clientmessages);
    };
  
    handleDefault = (message) => {
      if(sessionStorage.getItem("userId")){
      const userId = sessionStorage.getItem("userId");
      let firstmessages;
      let requestPayload = {contacts:[{"profile":{"name":""},"wa_id":userId}],"messages":[{"from":userId,"id":userId,"text":{"body":message},"timestamp":"1689225224","type":"text"}]};
      axios.post(apiURL,requestPayload)
      .then((response) => 
      {
        let headermessage;
        let buttons;
        let temp;
        if(response.data.data.rasa){
          headermessage = response.data.data.rasa.text[0];
          buttons = response.data.data.rasa? response.data.data.rasa.rasaBotMenu : [];
          temp ={headermessage,buttons}
          firstmessages = this.createChatBotMessage(
            `${temp.headermessage}`,
            { widget: "rasamenulist", withAvatar: true , payload:{res : temp}}
            );
            this.addMessageToBotState(firstmessages);
        }
        else{
          let headermessage = response.data.data.interactive.body.text;
          let buttons = response.data.data.interactive? response.data.data.interactive.action.buttons : [];
          temp = {headermessage, buttons}
          firstmessages = this.createChatBotMessage(
            `${temp.headermessage}`,
            { widget: "menu", withAvatar: true, payload:{res:temp}}
            );
            this.addMessageToBotState(firstmessages);        
        }
      }
      )
    .catch(error => {
        console.log(error)
      })
    }
    else{
      this.handleUserRegistration(message);
    }
    };
  
    addMessageToBotState = (messages) => {
      if (Array.isArray(messages)) {
        this.setState((state) => ({
          ...state,
          messages: [...state.messages, ...messages],
        }));
      } else {
        this.setState((state) => ({
          ...state,
          messages: [...state.messages, messages],
        }));
      }
    };
  }
  
  export default ActionProvider;