import React from "react";
import "./BotAvatar.css";
import { Grid } from "@mui/material";
import chatBotImage from "../../assets/images/chat-bot.png"
const BotAvatar = () => {
  return (
<div className="botimage">
<Grid container spacing={2}>
    <Grid className="header_avatar"item xs={4}>
      <img className="bot-avatar" src={chatBotImage}/>
    </Grid>
</Grid>
</div>
  );
};

export default BotAvatar;
