import React from "react";

import "./TutorialSection.css";

const TutorialSection = () => {
  return (
    <div className="tutorial-section" id="tutorials">
      <h2 className="tutorial-header">Tutorials</h2>
      <div className="tutorial-container">
        <div className="tutorial-yt-container">
          <iframe
            width="200"
            height="175"
            src="https://www.youtube.com/embed/AeojRYwfAMo"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Basic setup"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default TutorialSection;
