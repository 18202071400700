import React from "react";
import "./InteractiveList.css";
import "../Menu/Menu.css"
const InteractiveList1 = (props) => {
  // const list = localStorage.getItem('list1');
  // const menu = JSON.parse(list)
  const menu = props.payload.res.list;
  console.log("props.payload.res.list",props.payload.res.list, menu )
    console.log("menu", menu)
  const Options = menu && menu.map((option)=>(
    <div className="list" onClick={(e)=>props.actionProvider.handleMenuClick1(e, option.title,option.id, option.description)}>
        <div>
            <label for={option.id}>{option.title}</label>
            <p for={option.id}>{option.description}</p>
        </div>
     <input type="radio" id={option.id}  name="fav_language" value={option.title}/>
    </div>
    ))
  return (
    <div className="list_div">
        {Options}
{/* <button type="submit" className="menu-link" >Select</button> */}
    </div>
  );
};

export default InteractiveList1;
