// Config starter code
import { createChatBotMessage } from "react-chatbot-kit";
import MessageParser from "../chatbot/MessageParser";
import Menu from "../components/Menu/Menu";
import MessageParserSection from "../components/MessageParserSection/MessageParserSection";
import TutorialSection from "../components/TutorialSection/TutorialSection"
import Header from "../components/Header/Header";
import InteractiveList from "../components/InteractiveList/InteractiveList";
import InteractiveList1 from "../components/InteractiveList/InteractiveList1";
import RasaMenuList from "../components/InteractiveList/RasaMenuList";
import BotAvatar from "../components/BotAvatar/BotAvatar";

const config = {
  initialMessages: [createChatBotMessage(`Hello!!`)],
  botName: "SIER Chatbot",
  customComponents: {
    
    header: () => <Header/>,
    botAvatar: (props) => <BotAvatar {...props} />,

 },
 state: {
  rasaResponse: [],
  infoBox: '',
},
 customStyles: {
  // Overrides the chatbot message styles
  botMessageBox: {
    backgroundColor: "#fff",
  },
  // Overrides the chat button styles
},
 widgets: [
    {
      widgetName: "menu",
      widgetFunc: (props) => <Menu {...props} />,
      props: {},

    },
    {
        widgetName: "messageparser",
        widgetFunc: (props) => <MessageParserSection {...props} />,
        props: {},
      },
      {
        widgetName: "tutorial",
        widgetFunc: (props) => <TutorialSection {...props} />,
        props: {},
      },
      {
        widgetName: "interactivelist",
        widgetFunc: (props) => <InteractiveList {...props} />,
        props: {},
      },
      {
        widgetName: "interactivelist1",
        widgetFunc: (props) => <InteractiveList1 {...props} />,
        props: {},
      },
      {
        widgetName: "rasamenulist",
        widgetFunc: (props) => <RasaMenuList {...props} />,
        props: {},
      },
  ],};

export default config;