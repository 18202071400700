import React from "react";
import "./InteractiveList.css";
import "../Menu/Menu.css"
const RasaMenuList = (props) => {
  // console.log("props LIST", props, props?.payload?.res);

    const handleSubmit = (title)=>{
        console.log("selected Option>>", title);  
        props.actionProvider.handleRasaRequest(title);
      }
      let menu;
      if(props.payload.res){
        menu = props.payload.res.buttons;
      }
      else{
        const list = localStorage.getItem('rasa_menu_list') ;
        menu = JSON.parse(list)
      }
  const Options = menu && menu.map((option)=>(
    <div className="list" onClick={()=>handleSubmit(option)}>
        <div >
            <label for={option}>{option}</label>
        </div>
     <input type="radio" id={option.id}  name="fav_language" value={option}/>
    </div>
    ))
  return (
    <div className="list_div">
        {Options}
    </div>
  );
};

export default RasaMenuList;
