import React from "react";
import Chatbot from "react-chatbot-kit";
import 'react-chatbot-kit/build/main.css';
import './App.css';
import config from "./configs/chatbotConfig";
import MessageParser from "./chatbot/MessageParser";
import ActionProvider from "./chatbot/ActionProvider";
import ChatComponent from "./components/ChatComponent/ChatComponent";
import {chatbotimg} from "./assets/images/chatbot-bg-img.jpg"
import 'animate.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

function App() {
// if(window.location.reload){
//   sessionStorage.clear()
// }
  return (
    <Router>
 
    <div  className="App">
      {/* <header className="App-header">
        <ChatComponent/>
      <Chatbot
        config={config}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
        />
        </header> */}
        <Routes>
          <Route exact path="/" element={
            <div className="chatbot-bg">

              <header className="App-header">
                  <ChatComponent/>
              </header>
            </div>
          }/>
          <Route exact path="/chatbot" element={
                <header className="App-header">

                  <ChatComponent/>
                  </header>

              }/>
        </Routes>
    </div>
    </Router>
  );
}

export default App;