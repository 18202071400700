import React from "react";

import "./Header.css";
import { Grid } from "@mui/material";
import botImage from "../../assets/images/SIER-BOT-LOGO.png"
const Header = () => {
  return (
<div className="header">
<Grid container spacing={2}>
    <Grid className="header_avatar"item xs={4}>
      <img className="avatar" src={botImage}/>
        {/* <div className="avatar"></div> */}
    </Grid>
    <Grid className="header_text" item xs={8}>
        <div className="botname">SIER Chatbot</div>
        <div className="status">Online</div>
    </Grid>
</Grid>
</div>
  );
};

export default Header;
