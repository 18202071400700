import React, { Component } from 'react';
import Chatbot from "react-chatbot-kit";
import Button from '@mui/material/Button';
import config from "../../configs/chatbotConfig";
import MessageParser from "../../chatbot/MessageParser";
import ActionProvider from "../../chatbot/ActionProvider";
import CloseIcon from '@mui/icons-material/Close';
import CommentIcon from '@mui/icons-material/Comment';

const style = {
 margin: 0,
 padding: 20,
 top: 'auto',
 borderRadius:'50%',
 right: 20,
 bottom: 20,
 left: 'auto',
 position: 'fixed',
 backgroundColor:'#E65100',
 color:'#FFFFFF',
};

  class ChatComponent extends Component {
   constructor( props ){
    super( props )
    this.state = { show : false };

    this.toggleDiv = this.toggleDiv.bind(this)
   }
   toggleDiv = () => {
    const { show } = this.state;
    this.setState( { show : !show } )
      sessionStorage.clear()
  }
  render() {
    return (
    <div>
      <div>
       { this.state.show && <Chatbot
        config={config}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
        /> }
      </div>
     <Button className="chat-bot-button" variant="fab" aria-label="add" style={style} onClick={ 
      this.toggleDiv } >
        {this.state.show ?<CloseIcon/> : <CommentIcon/> }
     </Button>
     </div>
     );
   }
  }
  export default ChatComponent;