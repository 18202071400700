import React from "react";
import "./Menu.css";

const Menu = (props) => {
  const handleSubmit = (title, id)=>{
    props.actionProvider.handleListClick(title, id) 
  }
  // const buttonOptions = JSON.parse(JSON.stringify(localStorage.getItem("buttons")))
  // const buttons = JSON.parse(buttonOptions)
  const buttons = props.payload.res.buttons;
  const menu = [];

  buttons.forEach((button)=>(
    menu.push(button)
  ))
  const Options = menu && menu.map((option)=>(
    <a key={option.reply.id} href="#" onClick={()=>{handleSubmit(option.reply.title,option.reply.id )}} className="menu-link">
      {option.reply.title}
    </a>
  ))
  return (
    <div className="menu">
      {Options}
    </div>
  );
};

export default Menu;
