import React from "react";
import "./InteractiveList.css";
import "../Menu/Menu.css"
const InteractiveList = (props) => {
  // const list = localStorage.getItem('list');
  // const menu = JSON.parse(list);
  const menu = props.payload.res.list;
  // console.log("props.payload.res.list", props.payload.res.list)

  const Options = menu && menu.map((option)=>(
    <div className="list" onClick={(e)=>props.actionProvider.handleMenuClick(e,option.title,option.id, option.description)}>
        <div for={option.id} >
            <label for={option.id}>{option.title}
           { option.desccription ?
              <p for={option.id}>{option.description}</p>
              :<></>
            }
            </label>
        </div>
              <input type="radio" id={option.id} name={option.id}  value={option.title}/>
    </div>
    ))
  return (
    <div className="list_div">
        {Options}
{/* <button type="submit" className="menu-link" >Select</button> */}
    </div>
  );
};

export default InteractiveList;
